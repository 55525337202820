import React from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface FooterProps {
    className?: string
}

const Footer: React.FC<FooterProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();
    const src1 = "/images/home/colab_network_logo.svg";
    const src2 = "/images/home/cda-amc_full_lockup.png";

    return (
        <div className={`footer-wrapper ${className} ${language}`}>
            <div className="section-container-wrapper">
                <div className="container">
                    <img className="left-logo" src={src1} alt="cadth_logo_footer" data-aos="fade-in"/>
                    <div className="right-column">
                        <div className="description-text">
                            {t('CoLab receives funding from Canada’s Drug Agency (CDA-AMC) through the Post-Market Drug Evaluation (PMDE) Program.')}
                            <br></br>
                            <br></br>
                            {t('The PMDE Program is run by CDA-AMC and is responsible for timely and credible evidence on the postmarket safety and effectiveness of drugs approved for use in Canada.')}
                            <br></br>
                            <br></br>
                            {t('CoLab is supported by CDA-AMC and its PMDE Program through funding provided by Health Canada.')}
                            <br></br>
                            <br></br>
                            {t(`CDA-AMC is a pan-Canadian health organization. Created and funded by Canada’s federal, provincial, and territorial governments, CDA-AMC is responsible for driving better coordination, alignment, and public value within Canada’s drug and health technology landscape.`)}
                            <br></br>
                            <img className={`logo ${language}`} src={src2} alt="cadth_logo" data-aos="fade-in" />
                            {t('© 2024 Canada’s Drug Agency')}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer