import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface CustomersProps {
    className?: string
}

interface SliderDot {
    index: number,
    selected: boolean
}

const Customers: React.FC<CustomersProps> = ({
    className = ''
}) => {
    const slider = useRef(null);
    const [dot1Active, setDot1Active] = useState(true);
    const [dot2Active, setDot2Active] = useState(false);
    const [fadeEdge, setFadeEdge] = useState('right');

    const { language, t } = useI18next();
    const logo1Src = language == 'en' ? "/images/home/customer_1_logo.webp" : "/images/home/fr/customer_1_logo.webp";
    const logo5Src = language == 'en' ? "/images/home/customer_5_logo.webp" : "/images/home/fr/customer_5_logo.webp";
    const logo6Src = language == 'en' ? "/images/home/customer_6_logo.webp" : "/images/home/fr/customer_6_logo.webp";

    const sliderDotsData: SliderDot[] = [
        { index: 0, selected: true },
        { index: 1, selected: false },
        { index: 2, selected: false },
        { index: 3, selected: false }
    ];
    const [sliderDots, setSliderDots] = useState<SliderDot[]>(sliderDotsData);

    const onNext = () => {
        setDot1Active(false);
        setDot2Active(true);

        if (slider.current) {
            (slider.current as Slider).slickNext();
        }
    }

    const onPrevious = () => {
        setDot1Active(true);
        setDot2Active(false);

        if (slider.current) {
            (slider.current as Slider).slickNext();
        }
    }

    const onNext1 = () => {
        if (slider.current) {
            (slider.current as Slider).slickNext();
        }
    }

    const onPrevious1 = () => {
        if (slider.current) {
            (slider.current as Slider).slickPrev();
        }
    }

    const settings: Settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        speed: 1600,
        autoplaySpeed: 5000,
        swipe: false,
        afterChange: (index) => {
            if (index == 0) {
                setFadeEdge('right');
            }
        },
        beforeChange: (_, newIndex) => {
            setDot1Active(newIndex == 0);
            setDot2Active(newIndex > 0);

            if (newIndex >= 1) {
                setFadeEdge('left');
            }

        },
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                    infinite: true,
                    afterChange: (index) => {
                        if (index == 0) {
                            setFadeEdge('right');
                        }
                    },
                    beforeChange: (_, index) => {
                        let newData: SliderDot[] = [];
                        sliderDotsData.forEach((_, itemIndex) => {
                            newData.push({ index: itemIndex, selected: Math.ceil(index / 2) == itemIndex })
                        })
                        setSliderDots(newData);

                        if (index >= 1) {
                            setFadeEdge('left');
                        }
                    }
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                    infinite: true,
                    afterChange: (index) => {
                        if (index == 0) {
                            setFadeEdge('right');
                        }
                    },
                    beforeChange: (_, index) => {
                        let newData: SliderDot[] = [];
                        sliderDotsData.forEach((_, itemIndex) => {
                            newData.push({ index: itemIndex, selected: Math.ceil(index / 2) == itemIndex })
                        })
                        setSliderDots(newData);

                        if (index >= 1) {
                            setFadeEdge('left');
                        }
                    }
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                    infinite: true,
                    afterChange: (index) => {
                        if (index == 0) {
                            setFadeEdge('right');
                        }
                    },
                    beforeChange: (_, index) => {
                        let newData: SliderDot[] = [];
                        sliderDotsData.forEach((_, itemIndex) => {
                            newData.push({ index: itemIndex, selected: Math.ceil(index / 2) == itemIndex })
                        })
                        setSliderDots(newData);

                        if (index >= 1) {
                            setFadeEdge('left');
                        }
                    }
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false,
                    infinite: true
                }
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    dots: false,
                    infinite: true
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    dots: false,
                    infinite: true
                }
            },
            {
                breakpoint: 1370,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false,
                    infinite: true
                }
            },
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false,
                    infinite: true
                }
            }
        ]
    };

    return (
        <div className={`customers-wrapper ${className} ${language}`}>
            <div className="section-container-wrapper">
                <div className="top-section">
                    <div className="left-section">
                        <div className="title-text">{t('CoLab works with a wide range of customers who have diverse questions and needs related to postmarket drug evaluation in Canada.')}</div>
                        <div className="description-text">{t('CoLab can provide timely and reliable evidence to pan-Canadian decision-makers, including:')}</div>
                        <br></br>
                    </div>
                    <div className="right-section">
                        <div className="dot-buttons">
                            <div className={dot1Active ? "filled-dot" : "empty-dot"}></div>
                            <div className={dot2Active ? "filled-dot" : "empty-dot"}></div>
                        </div>
                        <div className="slide-buttons" data-aos="fade-in">
                            <button className="prev-button" onClick={onPrevious}></button>
                            <button className="next-button" onClick={onNext}></button>
                        </div>
                    </div>
                </div>

                <div className={`slider-container fade-${fadeEdge} fade-right`}>
                    <Slider ref={slider} className={`customer-slider`} {...settings}>
                        <div className="customer-card">
                            <img src="/images/home/customer_1.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t('Health Canada')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.canada.ca/en/health-canada.html')} target={"_blank"}>{t('Website')}</a>
                                <img src={logo1Src} className="img1" alt="customer_logo" data-aos="fade-in" />
                            </div>
                        </div>
                        <div className="customer-card">
                            <img src="/images/home/customer_2.webp" height={127} alt="customer" data-aos="fade-in"/>
                            <div className={`title-text ${language}`}>{t('Public Health Agency of Canada (PHAC)')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.canada.ca/en/public-health.html')} target={"_blank"}>{t('Website')}</a>
                                <img src={logo1Src} className="img1" alt="customer_logo" data-aos="fade-in" />
                            </div>
                        </div>
                        <div className="customer-card">
                            <img src="/images/home/customer_3.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t('Federal Drug Programs')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.canada.ca/en/health-canada/services/health-care-system/pharmaceuticals/access-insurance-coverage-prescription-medicines/federal-public-drug-benefit-programs.html')} target={"_blank"}>{t('Website')}</a>
                            </div>
                        </div>
                        <div className="customer-card">
                            <img src="/images/home/customer_4.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t('Provincial and Territorial Drug Programs')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.canada.ca/en/health-canada/services/health-care-system/pharmaceuticals/access-insurance-coverage-prescription-medicines/provincial-territorial-public-drug-benefit-programs.html')} target={"_blank"}>{t('Website')}</a>
                            </div>
                        </div>

                        <div className="customer-card">
                            <img src="/images/home/customer_5.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t('Pan-Canadian Pharmaceutical Alliance (pCPA)')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.pcpacanada.ca/')} target={"_blank"}>{t('Website')}</a>
                                <img src={logo5Src} className="img2" alt="customer_logo" data-aos="fade-in" />
                            </div>
                        </div>
                        {/* <div className="customer-card">
                            <img src="/images/home/customer_6.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t('Patented Medicine Prices Review Board (PMPRB)')}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('http://pmprb-cepmb.gc.ca/home')} target={"_blank"}>{t('Website')}</a>
                                <img src={logo6Src} className="img2" alt="customer_logo" data-aos="fade-in" />
                            </div>
                        </div> */}
                        <div className="customer-card">
                            <img src="/images/home/customer_7.webp" height={127} alt="customer" data-aos="fade-in" />
                            <div className={`title-text ${language}`}>{t(`Institut national d'excellence en santé et services sociaux`)}</div>
                            <div className="card-bottom">
                                <a className="link" href={t('https://www.inesss.qc.ca/')} target={"_blank"}>{t('Website')}</a>
                                <img src="/images/home/customer_7_logo.webp" className="img3" alt="customer_logo" data-aos="fade-in" />
                            </div>
                        </div>
                    </Slider>
                </div>

                <div className="slider-section">
                    <div className="dot-buttons" id="dot-buttons-1">
                        {sliderDots.map(({ index, selected }) => (
                            <div key={index} className={selected ? "filled-dot" : "empty-dot"} />
                        ))}
                    </div>
                    <div className="dot-buttons" id="dot-buttons-2">
                        <div className={dot1Active ? "filled-dot" : "empty-dot"}></div>
                        <div className={dot2Active ? "filled-dot" : "empty-dot"}></div>
                    </div>
                    <div className="slide-buttons" ata-aos="fade-in">
                        <button className="prev-button" onClick={onPrevious1}></button>
                        <button className="next-button" onClick={onNext1}></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customers